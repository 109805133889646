import React, {useEffect, useState} from "react";
import {Card, Checkbox, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import PageWrapper from "pages/wrapper/PageWrapper";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import _ from "lodash";
import {match} from "react-router";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import {loading, loading as loadingHelper} from "components/common/loading/Loading";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import moment from "moment";
import {RequestCertificateGroupService} from "../../services/request-certificate-group/RequestCertificateGroupServices";
import {formatMoney, handleDateData, handleFormatString} from '../../helpers/NewCaCrmHelper';
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import {TextAreaWithLabel} from "../../components/common/form/input-with-label/TextAreaWithLabel";

const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface Props extends FormComponentProps {
    match: match<{ id: string }>;
    history: any;
}

const objectDate1 = {
    1: 'represen_passport_date'
};
const objectDate2 = {
    1: 'provide_date'
};
const objectDate3 = {
    1: 'date_expired_cert'
};
export const RequestCTSGroupUpdate: React.FC<Props> = props => {
    const id = props.match.params.id;
    const [provideDate, setProvideDate] = useState("");
    const [dateExpiredCert, setDateExpiredCert] = useState("");
    const [represenProvideDate, setRepresenProvideDate] = useState("");
    const [status, setStatus] = useState(0);
    const [reason, setReason] = useState("");
    const [password, setPassword] = useState("");
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [oldFile, setOldFile] = useState("");
    const [oldFile2, setOldFile2] = useState("");
    const [oldFile3, setOldFile3] = useState("");
    const [oldFilePassportFrontSide, setOldFilePassportFrontSide] = useState("");
    const [oldFilePassportBackSide, setOldFilePassportBackSide] = useState("");
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState([]);
    const [requiredTokenType, setRequiredTokenType] = useState(false);
    const [requiredSerialCts, setRequiredSerialCts] = useState(false);
    const [file, setFile] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const [isFileDk02, setIsFileDk02] = useState(false);
    const [versionToken, setVerionToken] = useState({});

    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Mã số doanh nghiệp");
    const [disableTaxCode, setDissableTaxCode] = useState(false);
    const [typeUpload, setTypeUpload] = useState(1);
    const [showFilePassPort, setShowFilePassPort] = useState(true);
    const [changeSetField, setChangeSetField] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [requireFileWhenExtend, setRequireFileWhenExtend] = useState(true);

    const [showViewFileLegal, setShowViewFileLegal] = useState(false);
    const [showViewFilePassport, setShowViewFilePassport] = useState(false);

    // const [disableField, setDisableField] = useState(false);
    // const [disableTypeDevice, setDisableTypeDevice] = useState(false);
    // const [disableHSMProvider, setDisableHSMProvider] = useState(false);
    const update = status => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loadingHelper.runLoadingBlockUI();
                    const val= {
                        ...values,
                        status,
                        showEmail
                    };
                    let valuesConvert = val;
                    if (val.represen_passport_date !== undefined && val.represen_passport_date !== null ){
                        valuesConvert = handleDateData(val, objectDate1);
                    }
                    if (val.provide_date !== undefined && val.provide_date !== null){
                        valuesConvert = handleDateData(valuesConvert, objectDate2);
                    }
                    if (val.date_expired_cert !== undefined && val.date_expired_cert !== null){
                        valuesConvert = handleDateData(valuesConvert, objectDate3);
                    }
                    const data = await RequestCertificateGroupService.update(id, valuesConvert);
                    if (data && Number(data.status) === 422) {
                        onFailAction("Có lỗi xảy ra khi cập nhật !");
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    }
                    else if (data && Number(data.status) === 200) {
                        onSuccessAction("Cập nhập yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-cts-to-chuc");
                        });
                    }
                    else {
                        onFailAction("Có lỗi xảy ra khi cập nhật yêu cầu!");
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi cập nhật yêu cầu!");
                } finally {
                    loadingHelper.stopRunLoading();
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const fetchRequestGroup = async () => {
        const result = await RequestCertificateGroupService.getRequestCertificate(id);
        const province = await RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
        const district = await RequestCertificateGroupService.getDistrictByProvince(result.data.province_code);
        setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
        setProvideDate(result.data.organization.provide_date);
        setDateExpiredCert(result.data.date_expired_cert);
        setRepresenProvideDate(result.data.organization.represen_passport_date);
        setTypeUpload(result.data.organization.passport_upload_type)

        result.data.list_document.forEach(function (value) {
            if(value['type'] === 5 && value['file']){
                setOldFile(value['file'].split("/").pop());
                props.form.setFieldsValue({file_legal: value['file'].split("/").pop()})
            }else if(value['type'] === 6){
                if (result.data.organization.passport_upload_type === 1 && value['file']) {
                    setOldFile2(value['file'].split("/").pop());
                    props.form.setFieldsValue({file_deputy_passport: value['file'].split("/").pop()})
                }else {
                    if(result.data.organization['represen_passport_front_side']){
                        setOldFilePassportFrontSide(result.data.organization['represen_passport_front_side'].split("/").pop());
                        props.form.setFieldsValue({passport_front_side: result.data.organization['represen_passport_front_side'].split("/").pop()})
                    }
                    if(result.data.organization['represen_passport_back_side']){
                        setOldFilePassportBackSide(result.data.organization['represen_passport_back_side'].split("/").pop());
                        props.form.setFieldsValue({passport_back_side: result.data.organization['represen_passport_back_side'].split("/").pop()})
                    }
                }
            }else if(value['type'] === 3 && value['file']){
                setOldFile3(value['file'].split("/").pop());
                props.form.setFieldsValue({file_register_paper: value['file'].split("/").pop()})
            }else if(value['type'] === 7){
                setIsFileDk02(true);
            }
            if  (Number(value.type) === 5  && value.file && value.file.length > 0) {
                setShowViewFileLegal(true)
            }
            if  (Number(value.type) === 6  && value.file && value.file.length > 0) {
                setShowViewFilePassport(true)
            }
        });
        setStatus(result.data.status);
        setShowEmail(result.data.show_email);
        setReason(result.data.reason);
        setPassword(result.data.password);
        props.form.setFieldsValue({
            type_legal: result.data.organization.type_legal ? result.data.organization.type_legal.toString() : "",
            code: result.data.organization.code,
            identity_type: result.data.organization.identity_type.toString(),
            provide_organization: result.data.organization.provide_organization,
            tax_code: result.data.organization.tax_code,
            fullname: result.data.organization.fullname,
            address: result.data.organization.address,
            email: result.data.organization.email,
            phone: result.data.organization.phone,
            object: result.data.object.toString(),
            type_docurment: result.data.type_docurment.toString(),
            type_device: result.data.type_device.toString(),
            package_id: result.data.package_id.toString(),
            package_price: result.data.package_price,
            represent_fullname: result.data.organization.represent_fullname,
            represen_passport: result.data.organization.represen_passport,
            represen_passport_place: result.data.organization.represen_passport_place,
            represen_position: result.data.organization.represen_position,
            represen_email: result.data.organization.represen_email,
            represen_phone: result.data.organization.represen_phone,
            province_code: result.data.province_code.toString(),
            district_code: result.data.district_code.toString(),
            provider_id: result.data.provider_id.toString(),
            quantity: result.data.quantity,
            promotion_code: result.data.promotion_code,
            // sp_fullname: result.data.requestsupport.fullname,
            // sp_position: result.data.requestsupport.position,
            // sp_email: result.data.requestsupport.email,
            // sp_phone: result.data.requestsupport.phone,
            passport_upload_type: result.data.organization.passport_upload_type ? result.data.organization.passport_upload_type.toString() : null,
        });
        if (result.data.object === 2 || result.data.object === 3){
            props.form.setFieldsValue({
                date_expired_cert: result.data.date_expired_cert ? moment(result.data.date_expired_cert): null,
            })
        }
        if (result.data.object === 2 ){
           setRequireFileWhenExtend(false)
        }

        if (result.data.version_token_newtel){
            props.form.setFieldsValue({
                version_token_newtel: result.data.version_token_newtel.toString(),
            })
        }
        if (Number(result.data.organization.identity_type) === 1){
            setDissableTaxCode(true);
            setLabelCode("Mã số thuế");
        }if (Number(result.data.organization.identity_type) === 2){
            setLabelCode("Mã ngân sách");
        }if (Number(result.data.organization.identity_type) === 3){
            setLabelCode("Số quyết định");
        }if (Number(result.data.organization.identity_type) === 4){
            setLabelCode("Mã bảo hiểm xã hội");
        }if (Number(result.data.organization.identity_type) === 5){
            setLabelCode("Giáy phép đầu tư");
        }
        if (Number(result.data.object) === 2 || Number(result.data.object) === 3){
            if (Number(result.data.object) === 2){
                setRequiredSerialCts(true);
                setRequiredTokenType(true);
            }
            if (result.data.serial_cts){
                props.form.setFieldsValue({
                    serial_cts: result.data.serial_cts,
                });
            }
            if (result.data.token_type){
                props.form.setFieldsValue({
                    token_type: result.data.token_type.toString(),
                });
            }

        }
        if (Number(result.data.type_device) === 2){
            props.form.setFieldsValue({
                hsm_provider: result.data.hsm_provider.toString(),
            });
        }
        const type_search = 4;
        const type = props.form.getFieldValue("object");
        const object = 3;
        const type_device = props.form.getFieldValue("type_device");
        const hsm_provider = props.form.getFieldValue("hsm_provider");
        const data = await getCateServicePackage(type_search, type, object, type_device, result.data.provider_id, hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
    };
    const getCateServicePackage = async (type_search, type, obj, type_device, provider_id,hsm_provider) =>{
        return await RequestCertificateGroupService.getListCateServicePackage(type_search, type, obj,type_device, provider_id, hsm_provider);
    };

    const object = props.form.getFieldValue("object");
    const token_type = props.form.getFieldValue("token_type");

    useEffect(() => {
      if (object && token_type) {
        if ((Number(object) === 3)){
            if (Number(token_type) ===2){
                setRequiredSerialCts(true);
            }else{
                setRequiredSerialCts(false);
            }
        }
      }
    }, [object, token_type])
    
    const changeObject = async (e)=>{
        if (Number(e.target.value) === 2){
            setRequiredSerialCts(true);
            setRequiredTokenType(true);
        }else if (Number(e.target.value) === 3)
        {
            setRequiredSerialCts(false);
            setRequiredTokenType(false);
        }
        props.form.setFieldsValue({package_id: undefined});
        props.form.setFieldsValue({package_price: undefined});
        if (props.form.getFieldValue("code").trim() === ""){
            onFailAction("Bạn chưa nhập định danh.");
        }
        let type_search = 4;
        let type = e.target.value;
        let provider_id = props.form.getFieldValue("provider_id");
        const hsm_provider = props.form.getFieldValue("hsm_provider");
        let obj = 3;
        let type_device = props.form.getFieldValue("type_device");
        const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
        setCateServicePackageAmount(catePackage.data);
        setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
        if (Number(e.target.value) === 2) {
            setRequireFileWhenExtend(false);
        }else{
            setRequireFileWhenExtend(true);
        }
    };

    const onChangeTypeDevice = async (e) => {
        props.form.setFieldsValue({package_id: undefined});
        props.form.setFieldsValue({package_price: undefined});
        props.form.setFieldsValue({hsm_provider: undefined});
        const type_search = 4;
        const type = props.form.getFieldValue("object");
        const hsm_provider = props.form.getFieldValue("hsm_provider");
        const object = 3;
        const type_device = e.target.value;
        loading.runLoadingBlockUI();
        const data = await getCateServicePackage(type_search, type, object, type_device, props.form.getFieldValue("provider_id"), hsm_provider);

        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        loading.stopRunLoading();
    };

    const onChangeHSMProvider = async (e) =>{
        const type_search = 4;
        const type = props.form.getFieldValue("object");
        let obj = 3;
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = e.target.value;
        const type_device = props.form.getFieldValue("type_device");
        const data = await RequestCertificateGroupService.getListCateServicePackage(type_search, type, obj,type_device, provider_id, hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: '',
            package_price: ''
        });

    };
    const onViewFileDoc = async (type, typeUpload = '', location = '') => {
        try {
            let token = localStorage.getItem("currentUser");
            if (token){
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/yeu-cau-chung-thu-so-to-chuc/getFileDoc/
                ${password}/${type}?token=${obj.token}&typeUpload=${typeUpload}&location=${location}`;
                window.open(link);
            }

        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem trước file!");
        }
    };

    const onPreviewFile = async (typeFile) => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    setVisibleModal(true);
                    setLoadingModal(true);
                    const val= {
                        ...values,
                        typeFile: typeFile
                    };

                    let valuesConvert = val;
                    if (val.represen_passport_date !== undefined && val.represen_passport_date !== null ){
                        valuesConvert = handleDateData(val, objectDate1);
                    }
                    if (val.provide_date !== undefined && val.provide_date !== null){
                        valuesConvert = handleDateData(valuesConvert, objectDate2);
                    }
                    if (val.date_expired_cert !== undefined && val.date_expired_cert !== null){
                        valuesConvert = handleDateData(valuesConvert, objectDate3);
                    }
                    const data = await RequestCertificateGroupService.previewFile(valuesConvert);
                    setFile(data.base64);
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            }else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onOkModal = () => {
        setVisibleModal(false);
        setFile("");
    };
    const getProvince = ()=>{
        const province = RequestCertificateGroupService.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));

    };
    const onChangeProvince = async (e) =>{
        if(e){
            const district = await RequestCertificateGroupService.getDistrictByProvince(e);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
        } else{
            setDistrict({});
        }
        props.form.setFieldsValue({district_code: undefined})
    };
    const getProvider = async () => {
        const data = await RequestCertificateGroupService.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const getVersionToken = async () => {
        const data = await RequestCertificateGroupService.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    };
    const onChangeProvider = (e) =>{
        props.form.resetFields(["type_device"]);
    };
    const onChangeFormatInput = (name, value, type) =>{
        const strFormart = handleFormatString(value, type);
        props.form.setFieldsValue({[name]: strFormart})
    };
    const onChangePackage = async value => {
        const selectdCate: any = _.find(cateServicePackageAmount, { id: parseInt(value) });
        if(selectdCate){
            props.form.setFieldsValue({ package_price: formatMoney(selectdCate.price) });
        }
    };
    const onChangePrice = (e) =>{
        const price = formatMoney(e.target.value);
        props.form.setFieldsValue({package_price: price})
    };
    const onChangePromotionCode = (e) =>{
        if (e){
            props.form.setFieldsValue({
                promotion_code: e.target.value
            });
        }
    };
    const onChangeAdjourn = async e =>{
        const serial_cts = handleFormatString(e.target.value.replace(/ /g,''), 3);
        props.form.setFieldsValue({ serial_cts : serial_cts });
        if (serial_cts){
            await onCheckSerial(serial_cts)
        }
    };

    const onCheckSerial = async (serial_cts)=>{
        if (Number(props.form.getFieldValue("provider_id")) === 1){
            loading.runLoadingBlockUI();
            const identity_code = props.form.getFieldValue("code");
            const object = props.form.getFieldValue("object");

            const dataRequestCert = await RequestCertificateGroupService.getRequestBySerial(
                { 
                    serial_cts,
                    identity_code,
                    object
                });
            if(dataRequestCert.status === 200){
                if (dataRequestCert.data.status === 1 || dataRequestCert.data.status === 9){
                    // setDisableField(false);
                    props.form.setFieldsValue({
                        serial_cts : serial_cts,
                        type_legal: dataRequestCert.data.requestcertificate.organization.type_legal.toString(),
                        code: dataRequestCert.data.requestcertificate.organization.code,
                        provide_organization: dataRequestCert.data.requestcertificate.organization.provide_organization,
                        tax_code: dataRequestCert.data.requestcertificate.organization.tax_code,
                        fullname: dataRequestCert.data.requestcertificate.organization.fullname,
                        address: dataRequestCert.data.requestcertificate.organization.address,
                        email: dataRequestCert.data.requestcertificate.organization.email,
                        phone: dataRequestCert.data.requestcertificate.organization.phone,

                        type_device: dataRequestCert.data.requestcertificate.type_device.toString(),
                        provider_id: dataRequestCert.data.requestcertificate.provider_id.toString(),
                        represent_fullname: dataRequestCert.data.requestcertificate.organization.represent_fullname,
                        represen_passport: dataRequestCert.data.requestcertificate.organization.represen_passport,
                        represen_passport_place: dataRequestCert.data.requestcertificate.organization.represen_passport_place,
                        represen_position: dataRequestCert.data.requestcertificate.organization.represen_position,
                        represen_email: dataRequestCert.data.requestcertificate.organization.represen_email,
                        represen_phone: dataRequestCert.data.requestcertificate.organization.represen_phone,
                        certificate_end: dataRequestCert.data.certificateEnd,
                        // sp_fullname: dataRequestCert.data.requestcertificate.requestsupport.fullname,
                        // sp_position: dataRequestCert.data.requestcertificate.requestsupport.position,
                        // sp_email: dataRequestCert.data.requestcertificate.requestsupport.email,
                        // sp_phone: dataRequestCert.data.requestcertificate.requestsupport.phone,
                    });

                    if(dataRequestCert.data.requestcertificate.organization.represen_passport_date){
                        props.form.setFieldsValue({
                            represen_passport_date: moment(dataRequestCert.data.requestcertificate.organization.represen_passport_date)
                        })
                    }
                    if(dataRequestCert.data.requestcertificate.organization.provide_date){
                        props.form.setFieldsValue({
                            provide_date: moment(dataRequestCert.data.requestcertificate.organization.provide_date)
                        })
                    }
                    if(dataRequestCert.data.requestcertificate.date_expired_cert){
                        props.form.setFieldsValue({
                            date_expired_cert: moment(dataRequestCert.data.requestcertificate.date_expired_cert)
                        })
                    }
                    // setDisableTypeDevice(true);
                    // setDisableHSMProvider(true);
                    let type_search = 4;
                    let type = props.form.getFieldValue("object");
                    let provider_id = props.form.getFieldValue("provider_id");
                    let hsm_provider = props.form.getFieldValue("hsm_provider");
                    let obj = 3;
                    let type_device = dataRequestCert.data.requestcertificate.type_device.toString();
                    const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                }
            }
            if (Number(dataRequestCert.status) === 101 || Number(dataRequestCert.status) === 102){
                let mess= "Serial chứng thư số không hợp lệ!";
                if (Number(dataRequestCert.status) === 102){
                    mess = dataRequestCert.message;
                }
                onFailAction(
                    mess,
                    () => {
                        props.form.setFieldsValue({
                            serial_cts: "",
                        });
                    },
                    "warning",
                    "Cảnh báo"
                );
            }
            if (dataRequestCert.status === 103){
                if (dataRequestCert.data.status === 1 || dataRequestCert.data.status === 9){
                    // setDisableField(false);
                    props.form.setFieldsValue({
                        type_device: dataRequestCert.data.requestcertificate.type_device.toString(),
                        certificate_end: dataRequestCert.data.certificateEnd,
                    });
                    // setDisableTypeDevice(true);
                    // setDisableHSMProvider(true);
                    let type_search = 4;
                    let type = props.form.getFieldValue("object");
                    let provider_id = props.form.getFieldValue("provider_id");
                    let hsm_provider = props.form.getFieldValue("hsm_provider");
                    let obj = 3;
                    let type_device = dataRequestCert.data.requestcertificate.type_device.toString();
                    const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id,hsm_provider);
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                }
            }
            if (dataRequestCert.status === 104){
                // setDisableField(false);
                props.form.setFieldsValue({
                    type_device: '1',
                    certificate_end: dataRequestCert.data.certificateEnd,
                });
                // setDisableTypeDevice(true);
                // setDisableHSMProvider(true);
                let type_search = 4;
                let type = props.form.getFieldValue("object");
                let provider_id = props.form.getFieldValue("provider_id");
                let hsm_provider = props.form.getFieldValue("hsm_provider");
                let obj = 3;
                let type_device = 1;
                const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id,hsm_provider);
                setCateServicePackageAmount(catePackage.data);
                setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
            }
        }
    }

    const onChangeTypeExtend = async (e)=>{
        props.form.setFieldsValue({ type_extend: e.target.value });
        const serial_cts = props.form.getFieldValue("serial_cts");
         if (serial_cts){
            await onCheckSerial(serial_cts)
         }
     }

    const onChangeRepresentName = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_fullname: e.target.value
            });
        }
        props.form.setFieldsValue({
            represent_fullname : ''
        });
    };
    const onChangeRepresentPosition = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_position: e.target.value
            });
        }
        props.form.setFieldsValue({
            represen_position : ''
        });
    };
    const onChangeRepresentEmail = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_email: e.target.value
            });
        }
        props.form.setFieldsValue({
            represen_email : ''
        });
    };
    const onChangeRepresentPhone = (e) =>{
        if (e){
            props.form.setFieldsValue({
                sp_phone: e.target.value
            });
        }
        props.form.setFieldsValue({
            represen_phone : ''
        });
    };
    const onChangeTypeDoc = (e) =>{
        if (Number(e.target.value) === 1 && (Number(props.form.getFieldValue("object")) === 2 || Number(props.form.getFieldValue("object")) === 3) ){
            setChangeSetField(true);
        }else {
            setChangeSetField(false);
        }
    };
    const onChangeCheckBox = e => {
        setShowEmail(e.target.checked)
    }
    useEffect(() => {
        getProvince();
        fetchRequestGroup();
        getProvider();
        getVersionToken();
        // eslint-disable-next-line
    }, []);

    const STATUS_TU_CHOI = 7;
    const onChangeTypeUpload = (e) =>{
        const type_upload = parseInt(e.target.value)
        setTypeUpload(type_upload)
        if (type_upload !== typeUpload) {
            setShowFilePassPort(false)
            setOldFile2("")
        }
    };
    const onBlurEmail = e => {
        props.form.setFieldsValue({
            represen_email: e.target.value
        });
    }
    const onBlurPhone = e => {
        props.form.setFieldsValue({
            represen_phone: e.target.value
        });
    }
    return (
        <PageWrapper title="Cập nhật yêu cầu chứng thư số tổ chức">
            <ModalDisplayFile
                titleModal="File"
                visibleModal={visibleModal}
                loadingModal={loadingModal}
                fileBase64={file}
                onOkModal={()=>onOkModal()}
            />
            <InputWithLabel
                wrapClass={''}
                name={'isUpdate'}
                label={''}
                form={props.form}
                defaultValue={2}
                hidden={true}
            />
            <InputWithLabel
                wrapClass={''}
                name={'id'}
                label={''}
                form={props.form}
                defaultValue={id}
                hidden={true}
            />
            <Form>
                {status === STATUS_TU_CHOI ? (
                    <div className="input-group">
                        {" "}
                        <TextAreaWithLabel
                            label={"Lý do từ chối"}
                            name="reason-deny"
                            rows={0}
                            form={props.form}
                            defaultValue={reason}
                            wrapClass={"col-md"}
                            isDisabled={true}
                        />
                        {" "}
                    </div>
                ) : (
                    ""
                )}
                <Card className="container-fluid" title={<label>Thông tin gói chứng thư số</label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <SelectWithLabel
                            options={provider}
                            name="provider_id"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Nhà cung cấp"}
                            isRequired={true}
                            onChange={onChangeProvider}
                        />
                        <SelectWithLabel
                            options={{
                                1: 'Mã số thuế',
                                2: 'Mã ngân sách',
                                3: 'Số quyết định',
                                4: 'Mã bảo hiểm xã hội',
                                5: 'Giấy phép đầu tư'
                            }}
                            name="identity_type"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Loại định danh"}
                            isRequired={true}
                            isDisabled={true}
                        />
                        <InputWithLabel
                            form={props.form}
                            label={labelCode}
                            name="code"
                            isRequired={true}
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            isDisabled={true}
                        />
                        <RadioWithLabel
                            options={{1: "Cấp mới", 2: "Gia hạn", 3: "Chuyển đổi"}}
                            label="Đối tượng"
                            name="object"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={changeObject}
                        />
                        {/* {Number(props.form.getFieldValue("object")) === 2 &&
                            <RadioWithLabel
                                options={{
                                    1: "Trước hạn",
                                    2: "Quá hạn",
                                }}
                                label="Loại gia hạn"
                                name="type_extend"
                                wrappedClass="col-md-6 col-lg-3"
                                form={props.form}
                                isRequired={true}
                                onChange={onChangeTypeExtend}
                            />
                        } */}

                    </div>
                    <div className="input-group">
                        <RadioWithLabel
                            options={{
                                1: "Token",
                                2: "HSM",
                                // 3: "FastCA RS"
                        }}
                            label="Thiết bị đầu cuối thuê bao"
                            name="type_device"
                            wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            // isDisabled={true}
                            onChange={onChangeTypeDevice}
                        />
                        {Number(props.form.getFieldValue("type_device")) === 2 ? (
                            <RadioWithLabel
                                options={{1: "CyberLotus", 2: "Khác"}}
                                label="Nhà cung cấp HSM"
                                name="hsm_provider"
                                wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                                form={props.form}
                                isRequired={true}
                                onChange={onChangeHSMProvider}
                            />
                        ):("")}
                        <SelectWithLabel
                            options={cateServicePackage}
                            name="package_id"
                            wrappedClass="col-md-6 col-lg-2 "
                            form={props.form}
                            label={"Gói dịch vụ"}
                            isRequired={true}
                            onChange={onChangePackage}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Giá bán"
                            name="package_price"
                            wrapClass="col-md-6 col-lg-2"
                            isRequired={true}
                            onBlur={onChangePrice}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số lượng"
                            name="quantity"
                            wrapClass="col-md-6 col-lg-1"
                            isRequired={true}
                            type={'number'}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Mã CTKM"
                            name="promotion_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                            isRequired={false}
                            onChange={onChangePromotionCode}
                        />
                    </div>
                    <div className="input-group">
                        {Number(props.form.getFieldValue("object")) === 2 || Number(props.form.getFieldValue("object")) === 3? (
                            <React.Fragment>
                                <InputWithLabel
                                    form={props.form}
                                    label="Serial CTS"
                                    name="serial_cts"
                                    wrapClass="col-md-6 col-lg-4"
                                    isRequired={requiredSerialCts}
                                    onBlur={onChangeAdjourn}
                                />
                                <SelectWithLabel
                                    options={{1: "Token mới", 2: "Token cũ"}}
                                    name="token_type"
                                    wrappedClass="col-md-6 col-lg-2"
                                    form={props.form}
                                    label={"Loại token"}
                                    isRequired={requiredTokenType}
                                />
                                {Number(props.form.getFieldValue("object")) === 2 || Number(props.form.getFieldValue("object")) === 3 ? (
                            <SelectDateWithLabel
                                name="date_expired_cert"
                                form={props.form}
                                wrapClass="col-md-2 col-lg-2"
                                label="Ngày hết hạn"
                                defaultValue={dateExpiredCert ? moment(dateExpiredCert) : null}
                            />
                        ) : ("")}
                            </React.Fragment>
                        ): ('')}
                        {Number(props.form.getFieldValue('provider_id')) === 2 && Number(props.form.getFieldValue('type_device')) === 1 ? (
                            <SelectWithLabel
                                options={versionToken}
                                name="version_token_newtel"
                                wrappedClass="col-md-6 col-lg-2"
                                form={props.form}
                                label={"Version token"}
                            />
                        ) :("")}
                    </div>
                    <div className="input-group">
                        <div className={'col-md-12'}>
                            <Form.Item>
                                <Checkbox onChange={onChangeCheckBox}>Hiển thị Email</Checkbox>
                                <span className={'text-danger font-italic'}>
                                    ( Chú ý: Nếu chọn Email sẽ hiển thị trong thông tin CTS )
                                </span>
                            </Form.Item>
                        </div>
                    </div>
                </Card>
                <Card className="container-fluid mt-4" title={<label>Thông tin doanh nghiệp</label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <RadioWithLabel
                            options={{1: "Giấy ĐKKD", 2: "Giấy phép đầu tư", 3: "Quyết định thành lập"}}
                            label="Giấy tờ pháp lý"
                            name="type_legal"
                            wrappedClass="col-md-6 col-lg-5 select-doi-tuong"
                            form={props.form}
                            isRequired={requireFileWhenExtend}
                        />
                        {
                            showViewFileLegal ? (
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-md-6 col-lg-3"
                                    defaultLabel={oldFile}
                                    label="Tải file pháp lý"
                                    name="file_legal"
                                    form={props.form}
                                    isRequired={requireFileWhenExtend}
                                    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    onClickDownloadFile={() => onViewFileDoc(1)}
                                />
                            ) : (
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-md-6 col-lg-3"
                                    defaultLabel={oldFile}
                                    label="Tải file pháp lý"
                                    name="file_legal"
                                    form={props.form}
                                    isRequired={requireFileWhenExtend}
                                    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                />
                            )
                        }
                        <SelectDateWithLabel
                            name="provide_date"
                            form={props.form}
                            wrapClass="col-md-6 col-lg-2"
                            label="Ngày cấp"
                            defaultValue={provideDate ? moment(provideDate) : null}
                            rules={[
                                {
                                    validator: function(rule, value, callback) {
                                        if (value && value > moment()) {
                                            callback("Ngày cấp phải nhỏ hơn ngày hiện tại");
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                }
                            ]}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="provide_organization"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={255}
                        />
                    </div>
                    {/*line2*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="MST (nếu có)"
                            name="tax_code"
                            wrapClass="col-md-6 col-lg-2"
                            maxLength={16}
                            isDisabled={disableTaxCode}

                        />
                        <InputWithLabel
                            form={props.form}
                            label="Tên doanh nghiệp"
                            name="fullname"
                            wrapClass="col-md-6 col-lg-4"
                            isRequired={true}
                            maxLength={255}
                            onBlur={(e) =>  onChangeFormatInput('fullname', e.target.value, 3)}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="email"
                            wrapClass="col-md-6 col-lg-2"
                            isRequired={true}
                            maxLength={255}
                            onBlur={onBlurEmail}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="phone"
                            wrapClass="col-md-6 col-lg-4"
                            isRequired={true}
                            maxLength={16}
                            onBlur={onBlurPhone}
                        />
                    </div>
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Địa chỉ"
                            name="address"
                            wrapClass="col-md-8"
                            maxLength={255}
                        />
                        <SelectWithLabel
                            options={province}
                            name="province_code"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Tỉnh thành"}
                            isRequired={true}
                            isProvince={true}
                            onChange={onChangeProvince}
                        />
                        <SelectWithLabel
                            options={district}
                            name="district_code"
                            wrappedClass="col-md-6 col-lg-2"
                            form={props.form}
                            label={"Quận huyện"}
                            isRequired={true}
                        />
                        <div className="font-size-12 text-danger col-12">
                            Lưu ý: Thông tin Số điện thoại và Email bắt buộc nhập chính xác để xác minh khách hàng và nhận hỗ trợ dịch vụ từ NCC
                            </div>
                    </div>
                </Card>
                <Card className="container-fluid mt-4" title={<label>Người đại diện </label>} size="small">
                    {/*line 1*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Họ và tên"
                            name="represent_fullname"
                            wrapClass="col-md-6 col-lg-4"
                            isRequired={true}
                            maxLength={255}
                            onBlur={(e) =>  onChangeFormatInput('represent_fullname', e.target.value, 1)}
                            onChange={onChangeRepresentName}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số CMND"
                            name="represen_passport"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={16}
                        />
                        <SelectDateWithLabel
                            name="represen_passport_date"
                            form={props.form}
                            wrapClass="col-md-6 col-lg-2"
                            label="Ngày cấp"
                            defaultValue={represenProvideDate ? moment(represenProvideDate) : null}
                            rules={[
                                {
                                    validator: function(rule, value, callback) {
                                        if (value && value > moment()) {
                                            callback("Ngày cấp phải nhỏ hơn ngày hiện tại");
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                }
                            ]}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Nơi cấp"
                            name="represen_passport_place"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={255}
                        />
                    </div>
                    {/*line 2*/}
                    <div className="input-group">
                        <InputWithLabel
                            form={props.form}
                            label="Chức vụ"
                            name="represen_position"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={255}
                            onChange={onChangeRepresentPosition}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Email"
                            name="represen_email"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={255}
                            isRequired={false}
                            onChange={onChangeRepresentEmail}
                        />
                        <InputWithLabel
                            form={props.form}
                            label="Số điện thoại"
                            name="represen_phone"
                            wrapClass="col-md-6 col-lg-3"
                            maxLength={16}
                            isRequired={false}
                            onChange={onChangeRepresentPhone}
                        />
                    </div>
                    {/*line 3*/}
                    <div className="input-group">
                        <RadioWithLabel
                            options={{1: "Upload 1 file", 2: "Upload 2 file"}}
                            label="Hình thức upload"
                            name="passport_upload_type"
                            wrappedClass="col-lg-3 col-md-6 radio_register_support"
                            form={props.form}
                            isRequired={requireFileWhenExtend}
                            onChange={onChangeTypeUpload}
                        />
                        {typeUpload === 1 ? (
                            showViewFilePassport ? (
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-md-6 col-lg-3 white-space-initial"
                                    label={"Tải file CMND/Hộ chiếu"}
                                    defaultLabel={oldFile2}
                                    name={"file_deputy_passport"}
                                    form={props.form}
                                    extentionsAllow={['pdf', 'PDF', 'png', 'jpeg', 'jpg', 'PNG', 'JPG', 'JPEG']}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    isRequired={requireFileWhenExtend}
                                    isPassport={true}
                                    note={true}
                                    onClickDownloadFile={showFilePassPort ? () => onViewFileDoc(2, '', '') : false}
                                />
                            ) : (
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-md-6 col-lg-3 white-space-initial"
                                    label={"Tải file CMND/Hộ chiếu"}
                                    defaultLabel={oldFile2}
                                    name={"file_deputy_passport"}
                                    form={props.form}
                                    extentionsAllow={['pdf', 'PDF', 'png', 'jpeg', 'jpg', 'PNG', 'JPG', 'JPEG']}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    isRequired={requireFileWhenExtend}
                                    isPassport={true}
                                    note={true}
                                />
                            )
                        ) : (
                            showViewFilePassport ? (
                                    <div className="form-group col-md-6 col-lg-6 d-flex">
                                        <InputFileUpload
                                            nameFiledSetValue={""}
                                            classWrapped="col-md-6 col-lg-6 no-padding-left"
                                            label={"CMND (Mặt trước)"}
                                            defaultLabel={oldFilePassportFrontSide}
                                            name={"passport_front_side"}
                                            form={props.form}
                                            extentionsAllow={['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']}
                                            accept={[".png", ".jpg", ".jpeg"]}
                                            isPassport={true}
                                            isRequired={requireFileWhenExtend}
                                            note={true}
                                            onClickDownloadFile={showFilePassPort ? () => onViewFileDoc(2, '2', 'front') : false}
                                        />
                                        <InputFileUpload
                                            nameFiledSetValue={""}
                                            classWrapped="col-md-6 col-lg-6 no-padding-right"
                                            label="CMND (Mặt sau)"
                                            defaultLabel={oldFilePassportBackSide}
                                            name="passport_back_side"
                                            form={props.form}
                                            extentionsAllow={['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG']}
                                            accept={[".png", ".jpg", ".jpeg"]}
                                            isRequired={requireFileWhenExtend}
                                            isPassport={true}
                                            note={true}
                                            onClickDownloadFile={showFilePassPort ? () => onViewFileDoc(2, '2', 'back') : false}
                                        />
                                    </div>
                                ) :
                                (<div className="form-group col-md-6 col-lg-6 d-flex">
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6 col-lg-6 no-padding-left"
                                        label={"CMND (Mặt trước)"}
                                        defaultLabel={oldFilePassportFrontSide}
                                        name={"passport_front_side"}
                                        form={props.form}
                                        extentionsAllow={['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']}
                                        accept={[".png", ".jpg", ".jpeg"]}
                                        isPassport={true}
                                        isRequired={requireFileWhenExtend}
                                        note={true}
                                    />
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        classWrapped="col-md-6 col-lg-6 no-padding-right"
                                        label="CMND (Mặt sau)"
                                        defaultLabel={oldFilePassportBackSide}
                                        name="passport_back_side"
                                        form={props.form}
                                        extentionsAllow={['png', 'jpg', 'jpeg', 'JPG', 'PNG', 'JPEG']}
                                        accept={[".png", ".jpg", ".jpeg"]}
                                        isRequired={requireFileWhenExtend}
                                        isPassport={true}
                                        note={true}
                                    />
                                </div>))
                        }
                    </div>
                </Card>
                <Card className="container-fluid mt-4" title={<label>Mẫu đăng ký</label>} size="small">
                    <div className="input-group">
                        <RadioWithLabel
                            options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}}
                            label="Loại hồ sơ"
                            name="type_docurment"
                            wrappedClass="col-md-6 col-lg-3 select-doi-tuong"
                            form={props.form}
                            isRequired={true}
                            onChange={onChangeTypeDoc}
                        />
                        <div className="col-md-6 col-lg-3 mb-2">
                            <label className="invisible label-hide">11111998</label>
                            <button onClick={() => onPreviewFile(1)} className="btn btn-outline-success form-control">
                                Tải xuống DK-01.01
                            </button>

                        </div>
                        {Number(props.form.getFieldValue("provider_id")) === 2 ? (
                            <div className="col-md-6 col-lg-3 mb-2">
                                <label className="invisible label-hide">11111998</label>
                                <button onClick={() => onPreviewFile(2)} className="btn btn-outline-primary form-control">
                                    Tải xuống DK-02
                                </button>
                            </div>
                        ) : ("")}
                        {/*<div className="col-md-6 col-lg-3 mb-2">*/}
                        {/*    <label className="invisible label-hide">11111998</label>*/}
                        {/*    <button onClick={() => onPreviewFile(3)} className="btn btn-outline-primary form-control">*/}
                        {/*        Tải xuống DK-03*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                    <div className="input-group">
                        <InputFileUpload
                            classWrapped="col-md-6 col-lg-3"
                            label="Tải file DK 01.02"
                            name="file_register_paper"
                            defaultLabel={oldFile3}
                            form={props.form}
                            onClickDownloadFile={() => onViewFileDoc(3)}
                            extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}
                            accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                            isRequired={true}
                            onChangeSetField = {changeSetField}
                            nameFiledSetValue={"date_expired_cert"}
                        />
                        {Number(props.form.getFieldValue("provider_id")) === 2 ? (
                            <InputFileUpload
                                nameFiledSetValue={""}
                                classWrapped="col-md-6 col-lg-3"
                                label="Tải file DK-02"
                                name="file_dk_02"
                                form={props.form}
                                onClickDownloadFile={isFileDk02 ? () => onViewFileDoc(4) : ''}
                                extentionsAllow={['pdf', 'PDF']}
                                accept={".pdf"}
                            />
                        ) : ("")}
                        {/*<InputFileUpload*/}
                        {/*    nameFiledSetValue={""}*/}
                        {/*    classWrapped="col-md-6 col-lg-3"*/}
                        {/*    label="Tải file DK-03"*/}
                        {/*    name="file_dk_03"*/}
                        {/*    form={props.form}*/}
                        {/*    onClickDownloadFile={isFileDk03 ? () => onViewFileDoc(5) : ''}*/}
                        {/*    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}*/}
                        {/*    accept={[".pdf", ".png", ".jpg", ".jpeg"]}*/}
                        {/*/>*/}
                    </div>
                </Card>
            </Form>
            <div className="input-group d-flex justify-content-center p-5 mt-2">
                <div className="mt-2">
                    <ButtonOnSave
                        onClick={() => {
                            update(1);
                        }}
                        label="Lưu nháp"
                        className={"btn btn-primary btn-sm"}
                    />
                </div>
                <div className="mt-2">
                    <ButtonOnSave
                        onClick={() => {
                            update(2);
                        }}
                        label="Trình duyệt"
                        className={"btn btn-success btn-sm"}
                    />
                </div>
                <div className="mt-2">
                    <ButtonCancel
                        onClick={() => {
                            props.history.push("/yeu-cau-cts-to-chuc");
                        }}
                        className={"btn btn-default btn-sm"}
                    />
                </div>
            </div>
        </PageWrapper>
    );
};

const WrappedRequestCTSGroupUpdate = Form.create<Props>({
    name: "RequestCTSPersonalUpdate"
})(RequestCTSGroupUpdate);

export default WrappedRequestCTSGroupUpdate;
