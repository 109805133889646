import React, {useEffect, useState} from "react";
import PageWrapper from "../wrapper/PageWrapper";
import {Card, Checkbox, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import InputWithLabel from "components/common/form/input-with-label/InputWithLabel";
import SelectWithLabel from "components/common/form/input-with-label/SelectWithLabel";
import RadioWithLabel from "components/common/form/input-with-label/RadioWithLabel";
import ButtonOnSave from "components/common/form/button/ButtonOnSave";
import ButtonCancel from "components/common/form/button/ButtonCancel";
import {loading} from "components/common/loading/Loading";
import _ from "lodash";
import {onFailAction, onSuccessAction} from "helpers/SwalCommon";
import SelectDateWithLabel from "components/common/form/input-with-label/SelectDateWithLabel";
import InputFileUpload from "../../components/common/form/input-with-label/InputFileUpload";
import {RequestDigitalCertificatePersonalServices} from "../../services/request-digital-certificate-personal/RequestDigitalCertificatePersonalServices";
import moment from "moment";
import {formatMoney, handleDateData, handleFormatString} from '../../helpers/NewCaCrmHelper';
import ModalDisplayFile from "../../components/common/modal/display-file/ModalDisplayFile";
import {RequestCertificateGroupService} from "../../services/request-certificate-group/RequestCertificateGroupServices";
import "./css/RequestCTSPersonal.css";
const { REACT_APP_BASE_API_URL } = window['runConfig'];
interface Props extends FormComponentProps {
    user: any;
    history: any;
    defaultFileLabel?: string;
    isUpdateForm?: boolean;
    onClickDownloadFile?: any;
    disable?: boolean;
}

const objectDate = {
    1: 'passport_date',
};
const objectDate2 = {
    1: 'date_expired_cert'
};

export const RequestCTSPersonal: React.FC<Props> = props => {
    const [cateServicePackage, setCateServicePackage] = useState({});
    const [cateServicePackageAmount, setCateServicePackageAmount] = useState([]);
    const [versionToken, setVerionToken] = useState({});
    const [disableCode, setDisableCode] = useState(false);
    const [disableObject, setDisableObject] = useState(true);
    const [disableTypeDevice, setDisableTypeDevice] = useState(false);
    const [disableHSMProvider, setDisableHSMProvider] = useState(false);
    const [typeObject, setTypeObject] = useState(Number);
    const [isOrgan, setIsOrgan] = useState(Number);
    const [changeSetField, setChangeSetField] = useState(false);
    const [requiredTokenType, setRequiredTokenType] = useState(false);
    const [requiredSerialCts, setRequiredSerialCts] = useState(false);

    const [file, setFile] = useState("");
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const [province, setProvince] = useState({});
    const [district, setDistrict] = useState({});
    const [disableField, setDisableField] = useState(false);
    const [provider, setProvider] = useState({});
    const [labelCode, setLabelCode] = useState("Chứng minh nhân dân");
    const [disableIdenType, setDissableIdenType] = useState(false);
    const [typeUpload, setTypeUpload] = useState(1);
    const [fileLabelOrgan, setFileLabelOrgan] = useState("");
    const [dk01, setFileLabelDK01] = useState("");
    const [password, setPassword] = useState("");
    const [showEmail, setShowEmail] = useState(false);
    const [requireFileWhenExtend, setRequireFileWhenExtend] = useState(true);

    const storeRequest = status => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    loading.runLoadingBlockUI();
                    const val = {
                        ...values,
                        status,
                        showEmail
                    };
                    let valuesConvert = handleDateData(val, objectDate);
                    if (val.date_expired_cert !== undefined && val.date_expired_cert !== null){
                        valuesConvert = handleDateData(valuesConvert, objectDate2);
                    }
                    const data = await RequestDigitalCertificatePersonalServices.store(valuesConvert);
                    if (data && Number(data.status) === 422) {
                        _.forOwn(data.error, function (errors, key) {
                            props.form.setFields({
                                [key]: {
                                    errors: [new Error(errors.toString())]
                                }
                            });
                        });
                    } else if (data && Number(data.status) === 200) {
                        onSuccessAction("Lưu yêu cầu thành công", () => {
                            props.history.push("/yeu-cau-cts-ca-nhan");
                        });
                    } else {
                        onFailAction("Có lỗi xảy ra khi lưu !");
                    }
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi lưu !");
                } finally {
                    loading.stopRunLoading();
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const generateFile = async (typeFile) => {
        const {validateFields} = props.form;
        validateFields(async (errors, values) => {
            if (!errors) {
                try {
                    setVisibleModal(true);
                    setLoadingModal(true);
                    const val = {
                        ...values,
                        typeFile: typeFile
                    };
                    let valuesConvert = handleDateData(val, objectDate);
                    if (val.date_expired_cert !== undefined && val.date_expired_cert !== null){
                        valuesConvert = handleDateData(valuesConvert, objectDate2);
                    }
                    const data = await RequestDigitalCertificatePersonalServices.generateFile(valuesConvert);
                    setFile(data.base64);
                } catch (error) {
                    onFailAction("Có lỗi xảy ra khi xem trước file!");
                    setVisibleModal(false);
                } finally {
                    setLoadingModal(false);
                }
            } else {
                onFailAction("Bạn chưa điền đủ thông tin!");
            }
        });
    };
    const onOkModal = () => {
        setVisibleModal(false);
        setFile("");
    };

    const onChangeIsOrgan = async (e) => {
        setIsOrgan(e.target.value);
        if (Number(props.form.getFieldValue('object')) === 2) {
            let type_search = 4;
            let type = props.form.getFieldValue("object");
            let provider_id = props.form.getFieldValue("provider_id");
            let hsm_provider = props.form.getFieldValue("hsm_provider");
            let obj = 1;
            let type_device = props.form.getFieldValue("type_device");
            if (Number(e.target.value) === 1) {
                obj = 2
            }
            const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
            setCateServicePackageAmount(catePackage.data);
            setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));

        } else {
            props.form.setFieldsValue({
                type_device: '',
            });
            setCateServicePackage('');
        }
        props.form.setFieldsValue({
            package_id: '',
            package_price: ''
        })
    };
    const onChangeIdenType = async (e) => {
        if (e) {
            props.form.setFieldsValue({
                passport: '',
                object: undefined
            });
            if (Number(e) === 1) {
                setLabelCode("Chứng minh nhân dân");
                props.form.setFieldsValue({
                    tax_code: '',
                    passport: ''
                });
            }
            if (Number(e) === 2) {
                setLabelCode("Hộ chiếu");
            }
            if (Number(e) === 3) {
                setLabelCode("Căn cước công dân");
            }
            if (Number(e) === 4) {
                setLabelCode("Mã bảo hiểm xã hội");
            }
            if (Number(e) === 5) {
                setLabelCode("Mã nhân viên");
            }
        }

    };
    const changeObject = async (e) => {
        try {
            if (props.form.getFieldValue("passport").trim() === "") {
                onFailAction("Bạn chưa nhập định danh.");
            }
            setTypeObject(e.target.value);
            let passport = props.form.getFieldValue('passport');
            let idenType = props.form.getFieldValue('identity_type');
            const object = e.target.value;
            if (Number(object) === 2){
                setRequiredTokenType(true);
                setRequiredSerialCts(true);
            }else if (Number(object) === 3)
            {
                setRequiredTokenType(false);
                setRequiredSerialCts(false);
            }
            let result = await fetchInfo(passport, object, idenType);
            if (Number(e.target.value) === 2) {
                if (Number(result.status) === 101) {
                    onFailAction("Mã định danh không tồn tại trong hệ thống!", () => {
                        props.form.setFieldsValue({object: `1`.toString()});
                        setTypeObject(props.form.getFieldValue('object'));
                        setDisableCode(false);
                    }, "warning", "");
                } else {
                    setDisableCode(true);
                    setDissableIdenType(true);
                }
            }
            if (Number(e.target.value) === 2 && result.data) {
                setDisableCode(true);
            } else {
                setDisableCode(false);
            }
            if (Number(e.target.value) === 1 || Number(e.target.value) === 2 || Number(e.target.value) === 3) {
                setTypeObject(props.form.getFieldValue("object"));
                let type_search = 4;
                let type = props.form.getFieldValue("object");
                let obj = 1;
                let type_device = props.form.getFieldValue("type_device");
                if (Number(props.form.getFieldValue("is_organization")) === 1) {
                    obj = 2
                }
                let provider_id = props.form.getFieldValue("provider_id");
                let hsm_provider = props.form.getFieldValue("hsm_provider");
                const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
                setCateServicePackageAmount(catePackage.data);
                setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
            }
            props.form.setFieldsValue({
                package_id: '',
                package_price: ''
            });
    
            if (Number(e.target.value) === 2) {
                setRequireFileWhenExtend(false);
            }else{
                setRequireFileWhenExtend(true);
            }
        } catch (error) {
            console.error('error: ', error);
        }
    };
    const getCateServicePackage = async (type_search, type, obj, type_device, provider_id, hsm_provider) => {
        const data = await RequestDigitalCertificatePersonalServices.getListCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
        return data;
    };

    const object = props.form.getFieldValue("object");
    const token_type = props.form.getFieldValue("token_type");

    useEffect(() => {
      if (object && token_type) {
        if ((Number(object) === 3)){
            if (Number(token_type) ===2){
                setRequiredSerialCts(true);
            }else{
                setRequiredSerialCts(false);
            }
        }
      }
    }, [object, token_type])

    const changeTypeDevice = async (e) => {
        loading.runLoadingBlockUI();
        const type_search = 4;
        const type = typeObject;
        let obj = 1;
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = props.form.getFieldValue("hsm_provider");
        const type_device = e.target.value;
        if (Number(isOrgan) === 1) {
            obj = 2
        }
        const data = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: '',
            package_price: ''
        });
        loading.stopRunLoading();
    };
    const onChangeHSMProvider = async (e) => {
        const type_search = 4;
        const type = typeObject;
        let obj = 1;
        if (Number(isOrgan) === 1) {
            obj = 2
        }
        let provider_id = props.form.getFieldValue("provider_id");
        let hsm_provider = e.target.value;
        const type_device = props.form.getFieldValue("type_device");
        const data = await RequestDigitalCertificatePersonalServices.getListCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
        setCateServicePackageAmount(data.data);
        setCateServicePackage(_.mapValues(_.keyBy(data.data, "id"), "name"));
        props.form.setFieldsValue({
            package_id: '',
            package_price: ''
        });

    };

    const changeCode = async (e) => {
        if (e.target.value) {
            setDisableObject(false);
        } else {
            setDisableObject(true);
            props.form.setFields({"object": ''});
        }
    };
    const fetchInfo = async (passport, object, idenType) => {
        loading.runLoadingBlockUI();
        let result;
        try {
            result = await RequestDigitalCertificatePersonalServices.getInfo(passport, object, idenType);
            if (Number(object) === 1 || Number(object) === 3) {
                if (result && result.data) {
                    props.form.setFieldsValue({
                        passport: result.data.passport,
                        passport_place: result.data.passport_place ? result.data.passport_place.toString(): null,
                        fullname: result.data.fullname.toString(),
                        phone: result.data.phone,
                        email: result.data.email,
                        address: result.data.address,
                        tax_code: result.data.tax_code,
                        passport_date: moment(result.data.passport_date),
                        is_organization: result.data.is_organization ? result.data.is_organization.toString(): null,
                        // support_register: result.data.request.support_register.toString(),
                        identity_type: result.data.identity_type ? result.data.identity_type.toString() : null,
                    });
                    if (result.data.request){
                        console.log('result.data.request: ', result.data.request);
                        if(result.data.request.type_device){
                            props.form.setFieldsValue({
                                type_device: result.data.request.type_device.toString(),
                            });   
                        }
                        if(result.data.request.type_device){
                            props.form.setFieldsValue({
                            provider_id: result.data.request.provider_id.toString(),
                            });   
                        }
                    }
                    

                    // if (Number(result.data.request.support_register) === 1) {
                    //     props.form.setFieldsValue({
                    //         phone_support: result.data.request.requestsupport.phone,
                    //         email_support: result.data.request.requestsupport.email,
                    //         fullname_support: result.data.request.requestsupport.fullname,
                    //         position_support: result.data.request.requestsupport.positon,
                    //     });
                    // }
                    if (Number(result.data.request.type_device) === 2) {
                        props.form.setFieldsValue({
                            hsm_provider: result.data.request.hsm_provider.toString(),
                        });
                    }
                    if (Number(result.data.is_organization) === 1) {
                        props.form.setFieldsValue({
                            organization_name: result.data.organization_name,
                            organization_department: result.data.organization_department,
                            organization_tax_code: result.data.organization_tax_code,
                            organization_position: result.data.organization_position,
                            organization_email: result.data.organization_email,
                            organization_phone: result.data.organization_phone,
                            organization_address: result.data.organization_address,
                        });
                    }
                }
            }
            if (Number(object) === 2){
                if (Number(result.status) === 100){
                    props.form.setFieldsValue({
                        fullname: result.data.fullname,
                        address: result.data.address,
                        phone: result.data.phone,
                        email: result.data.email,
                    });
                    if (result.data.province_code && result.data.district_code){
                        props.form.setFieldsValue({
                            province_code: result.data.province_code.toString() ,
                            district_code: result.data.district_code.toString() ,
                        });
                    }else {
                        props.form.setFieldsValue({
                            province_code: "",
                            district_code: "",
                        });
                    }
                }
                props.form.setFieldsValue({serial_cts: ""})
            }
        } catch (error) {
            console.error('error: ', error);
        }
        
        loading.stopRunLoading();
        return result;
    };


    const onChangeProvince = async (e) => {
        if (e) {
            const district = await RequestDigitalCertificatePersonalServices.getDistrictByProvince(e);
            setDistrict(_.mapValues(_.keyBy(district, "newtel_district_code"), "fullname"));
        }
        props.form.setFieldsValue({
            district_code: undefined
        })
    };
    const onChangeAdjourn = async e => {
        const serial_cts = handleFormatString(e.target.value.replace(/ /g,''), 3);
        props.form.setFieldsValue({ serial_cts : serial_cts });
        if (serial_cts !== "") {
            props.form.setFieldsValue({ serial_cts: serial_cts });
            await onCheckSerial(serial_cts)
        }
    };

    const onCheckSerial = async (serial_cts)=>{
        loading.runLoadingBlockUI();
            const identity_code = props.form.getFieldValue("passport");
            const object = props.form.getFieldValue("object");
            if (Number(props.form.getFieldValue("object")) === 2){
                const dataRequestCert = await RequestDigitalCertificatePersonalServices.getRequestBySerial({serial_cts, identity_code, object});
                if (dataRequestCert.status === 200) {
                    if (dataRequestCert.data.status === 1 || dataRequestCert.data.status === 9) {
                        setDisableField(false);

                        props.form.setFieldsValue({
                            serial_cts: serial_cts,
                            passport: dataRequestCert.data.requestcertificate.requestpersonal.passport,
                            passport_place: dataRequestCert.data.requestcertificate.requestpersonal.passport_place.toString(),
                            fullname: dataRequestCert.data.requestcertificate.requestpersonal.fullname.toString(),
                            phone: dataRequestCert.data.requestcertificate.requestpersonal.phone,
                            email: dataRequestCert.data.requestcertificate.requestpersonal.email,
                            address: dataRequestCert.data.requestcertificate.requestpersonal.address,
                            tax_code: dataRequestCert.data.requestcertificate.requestpersonal.tax_code,
                            passport_date: moment(dataRequestCert.data.requestcertificate.requestpersonal.passport_date),
                            is_organization: dataRequestCert.data.requestcertificate.requestpersonal.is_organization.toString(),
                            // support_register: dataRequestCert.data.requestcertificate ? dataRequestCert.data.requestcertificate.support_register.toString() : "",
                            type_device: dataRequestCert.data.requestcertificate.type_device.toString(),
                            provider_id: dataRequestCert.data.requestcertificate.provider_id.toString(),
                            certificateEnd: dataRequestCert.data.certificateEnd,
                            // identity_type: dataRequestCert.data.requestcertificate.identity_type.toString(),
                        });
                        // if (Number(dataRequestCert.data.requestcertificate.support_register) === 1) {
                        //     props.form.setFieldsValue({
                        //         phone_support: dataRequestCert.data.requestcertificate.requestsupport.phone,
                        //         email_support: dataRequestCert.data.requestcertificate.requestsupport.email,
                        //         fullname_support: dataRequestCert.data.requestcertificate.requestsupport.fullname,
                        //         position_support: dataRequestCert.data.requestcertificate.requestsupport.positon,
                        //     });
                        // }
                        if (Number(dataRequestCert.data.requestcertificate.type_device) === 2) {
                            props.form.setFieldsValue({
                                hsm_provider: dataRequestCert.data.requestcertificate.hsm_provider.toString(),
                            });
                        }
                        if (Number(dataRequestCert.data.requestcertificate.requestpersonal.is_organization) === 1) {
                            props.form.setFieldsValue({
                                organization_name: dataRequestCert.data.requestcertificate.requestpersonal.organization_name,
                                organization_department: dataRequestCert.data.requestcertificate.requestpersonal.organization_department,
                                organization_tax_code: dataRequestCert.data.requestcertificate.requestpersonal.organization_tax_code,
                                organization_position: dataRequestCert.data.requestcertificate.requestpersonal.organization_position,
                                organization_email: dataRequestCert.data.requestcertificate.requestpersonal.organization_email,
                                organization_phone: dataRequestCert.data.requestcertificate.requestpersonal.organization_phone,
                                organization_address: dataRequestCert.data.requestcertificate.requestpersonal.organization_address,
                            });
                        }
                        setDisableTypeDevice(true);
                        setDisableHSMProvider(true);
                        const type_search = 4;
                        const type = typeObject;
                        let obj = 1;
                        let type_device = props.form.getFieldValue("type_device");
                        if (Number(isOrgan) === 1) {
                            obj = 2
                        }
                        let provider_id = props.form.getFieldValue("provider_id");
                        let hsm_provider = props.form.getFieldValue("hsm_provider");
                        const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
                        setCateServicePackageAmount(catePackage.data);
                        setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                    }
                }
                if (dataRequestCert.status === 101 || Number(dataRequestCert.status) === 102) {
                    let mess= "Serial chứng thư số không hợp lệ!";
                if (Number(dataRequestCert.status) === 102){
                    mess = dataRequestCert.message;
                }
                onFailAction(
                    mess,
                    () => {
                        props.form.setFieldsValue({
                            serial_cts: "",
                        });
                    },
                    "warning",
                    "Cảnh báo"

                );
                }
                if (dataRequestCert.status === 103) {
                    if (dataRequestCert.data.status === 1 || dataRequestCert.data.status === 9) {
                        setDisableField(false);
                        props.form.setFieldsValue({
                            type_device: dataRequestCert.data.requestcertificate.type_device.toString(),
                            certificateEnd: dataRequestCert.data.certificateEnd,

                        });
                        setDisableTypeDevice(true);
                        setDisableHSMProvider(true);
                        let type_search = 4;
                        let type = typeObject;
                        let obj = 1;
                        let type_device = props.form.getFieldValue("type_device");
                        if (Number(isOrgan) === 1) {
                            obj = 2
                        }
                        let provider_id = props.form.getFieldValue("provider_id");
                        let hsm_provider = props.form.getFieldValue("hsm_provider");
                        const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
                        setCateServicePackageAmount(catePackage.data);
                        setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                    }
                }
                if (dataRequestCert.status === 104) {
                    setDisableField(false);
                    props.form.setFieldsValue({
                        type_device: '1',
                        certificateEnd: dataRequestCert.data.certificateEnd,
                    });
                    setDisableTypeDevice(true);
                    setDisableHSMProvider(true);
                    let type_search = 4;
                    let type = typeObject;
                    let obj = 1;
                    let type_device = 1;
                    if (Number(isOrgan) === 1) {
                        obj = 2
                    }
                    let provider_id = props.form.getFieldValue("provider_id");
                    let hsm_provider = props.form.getFieldValue("hsm_provider");
                    const catePackage = await getCateServicePackage(type_search, type, obj, type_device, provider_id, hsm_provider);
                    setCateServicePackageAmount(catePackage.data);
                    setCateServicePackage(_.mapValues(_.keyBy(catePackage.data, "id"), "name"));
                }
            }else if (Number(props.form.getFieldValue("object")) === 3){
                const dataSend = {
                    serialCert: serial_cts
                };
                loading.runLoadingBlockUI();
                const checkSerial = await RequestDigitalCertificatePersonalServices.checkSerial(dataSend);
                if (checkSerial.status !== 200 ){
                    onFailAction(checkSerial.message, () => {
                        props.form.setFieldsValue({
                            serial_cts : ''
                        });
                    }, "warning", "")
                }
            }
    }
    const getProvince = async () => {
        const province = await RequestDigitalCertificatePersonalServices.getProvince();
        setProvince(_.mapValues(_.keyBy(province, "newtel_code"), "fullname"));
    };
    const getProvider = async () => {
        const data = await RequestDigitalCertificatePersonalServices.getAllProvider();
        setProvider(_.mapValues(_.keyBy(data, "id"), "name"));
    };
    const onChangeProvider = (e) => {
        props.form.resetFields(["type_device"]);
    };
    const getVersionToken = async () => {
        const data = await RequestCertificateGroupService.getVersionToken();
        setVerionToken(_.mapValues(_.keyBy(data.data, "id"), "code"));
    }
    const onChangeFormatInput = (name, value, type) => {
        const strFormart = handleFormatString(value, type);
        props.form.setFieldsValue({[name]: strFormart})
    };
    const onChange = async value => {
        const selectedCate: any = _.find(cateServicePackageAmount, {id: parseInt(value)});
        props.form.setFieldsValue({package_price: selectedCate ? formatMoney(selectedCate.price) : ''});
    };
    const onChangePrice = (e) => {
        let priceStr = e.target.value;
        let format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/; //eslint-disable-line
        if( format.test(priceStr) ){
            priceStr = priceStr.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
        }

        const price = formatMoney(priceStr);
        props.form.setFieldsValue({package_price: price})
    };
    const onChangePromotionCode = (e) =>{
        if (e){
            props.form.setFieldsValue({
                promotion_code: e.target.value
            });
        }
    };


    const onChangeTypeUpload = (e) => {
        if (e) {
            const type_upload = parseInt(e.target.value)
            setTypeUpload(type_upload)
        }
    };

    const onChangeOrganTaxCode = async (e) => {
        const dataSend = {
            organ_tax_code: e.target.value
        };
        const result = await RequestDigitalCertificatePersonalServices.searchRequestByOrganTaxCode(dataSend);
        if (Number(result.status) === 200) {
            setPassword(result.data.request.password)
            props.form.setFieldsValue({
                organization_name: result.data.organization_name,
                organization_department: result.data.organization_department,
                organization_tax_code: result.data.organization_tax_code,
                organization_position: result.data.organization_position,
                organization_email: result.data.organization_email,
                organization_phone: result.data.organization_phone,
                organization_address: result.data.organization_address,
            });
            let fileOrganName = '';
            let fileDK01 = '';
            result.data.list_doc.forEach(function (value) {
                if (value['type'] === 2 && value['file']) {
                    fileOrganName = value['file'].split("/");
                    props.form.setFieldsValue({
                        organization_file: value['file'],
                    });
                } else if (value['type'] === 4 && value['file']) {
                    fileDK01 = value['file'].split("/");
                    props.form.setFieldsValue({
                        file_register_paper: value['file'],
                    });
                }


            });
            setFileLabelOrgan(fileOrganName[3] ? fileOrganName[3] : '');
            setFileLabelDK01(fileDK01[3] ? fileDK01[3] : '');

        }

    };
    const onPreviewFile = async (type) => {
        loading.runLoadingBlockUI();
        try {
            let token = localStorage.getItem("currentUser");
            if (token) {
                let obj = JSON.parse(token);
                let link = `${REACT_APP_BASE_API_URL}qlbh/request-digital-certificate-personal/get-file/${password}/${type}?token=${obj.token}`;
                window.open(link)
            }
        } catch (error) {
            onFailAction("Có lỗi xảy ra khi xem file!");
        }
        loading.stopRunLoading();
    };
    const onChangeTypeDoc = (e) =>{
        if (Number(e.target.value) === 1 && (Number(props.form.getFieldValue("object")) === 2 || Number(props.form.getFieldValue("object")) === 3) ){
            setChangeSetField(true);
        }else {
            setChangeSetField(false);
        }
    };
    const onChangeCheckBox = e => {
        setShowEmail(e.target.checked)
    }

    useEffect(() => {
        getProvince();
        getProvider();
        getVersionToken();
    }, []);
    return (
        <div className="RequestCTSPersonalCreate ">
            <PageWrapper title="Tạo Yêu cầu CTS cá nhân">
                <Form>
                    <ModalDisplayFile
                        titleModal="File"
                        visibleModal={visibleModal}
                        loadingModal={loadingModal}
                        fileBase64={file}
                        onOkModal={() => onOkModal()}
                    />
                    <InputWithLabel
                        wrapClass={''}
                        name={'isUpdate'}
                        label={''}
                        form={props.form}
                        defaultValue={1}
                        hidden={true}
                    />
                    <Card className="container-fluid"
                          title={<label>Trường hợp cá nhân thuộc tổ chức doanh nghiệp </label>} size="small">
                        {/* <div className="input-group"> */}
                        <div className=" input-group">
                            <RadioWithLabel
                                label={""}
                                options={{1: "Có", 2: "Không"}}
                                name="is_organization"
                                wrappedClass="col-md-12 col-lg-6"
                                form={props.form}
                                isRequired={true}
                                onChange={onChangeIsOrgan}
                                isDisabled={disableField}
                            />
                        </div>
                        {props.form.getFieldValue("is_organization") === "1" ? (
                            <React.Fragment>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="MST tổ chức/doanh nghiệp"
                                        name="organization_tax_code"
                                        wrapClass="col-lg-3 col-md-6"
                                        isRequired={true}
                                        maxLength={16}
                                        isDisabled={disableField}
                                        onBlur={onChangeOrganTaxCode}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên tổ chức"
                                        name="organization_name"
                                        wrapClass="col-lg-3 col-md-6"
                                        isRequired={true}
                                        maxLength={255}
                                        isDisabled={disableField}
                                        onBlur={(e) => onChangeFormatInput('organization_name', e.target.value, 3)}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Tên phòng ban"
                                        name="organization_department"
                                        wrapClass="col-lg-3 col-md-6"
                                        maxLength={255}
                                        isDisabled={disableField}
                                        isRequired={false}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Chức vụ"
                                        name="organization_position"
                                        wrapClass="col-lg-3 col-md-6"
                                        maxLength={255}
                                        isDisabled={disableField}
                                        isRequired={false}
                                    />
                                </div>
                                <div className="input-group">
                                    <InputWithLabel
                                        form={props.form}
                                        label="Email"
                                        name="organization_email"
                                        wrapClass="col-lg-3 col-md-6"
                                        isRequired={true}
                                        maxLength={255}
                                        isDisabled={disableField}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Số điện thoại"
                                        name="organization_phone"
                                        wrapClass="col-lg-3 col-md-6"
                                        isRequired={true}
                                        maxLength={16}
                                        isDisabled={disableField}
                                    />
                                    <InputWithLabel
                                        form={props.form}
                                        label="Địa chỉ tổ chức/doanh nghiệp"
                                        name="organization_address"
                                        wrapClass="col-lg-3 col-md-6"
                                        isRequired={true}
                                        maxLength={255}
                                        isDisabled={disableField}
                                    />
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        defaultLabel={fileLabelOrgan}
                                        classWrapped="col-lg-3 col-md-6 white-space-initial"
                                        label="File xác nhận"
                                        name="organization_file"
                                        form={props.form}
                                        isRequired={requireFileWhenExtend}
                                        isDisabled={props.disable}
                                        onClickDownloadFile={fileLabelOrgan ? () => onPreviewFile(2) : ''}
                                        extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}
                                        accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    />

                                </div>
                            </React.Fragment>
                        ) : (
                            ""
                        )}


                        {/* </div> */}
                    </Card>
                    <Card className="container-fluid mt-4" title={<label>Đăng ký dịch vụ chứng thư số</label>}
                          size="small">
                        <div className="input-group">
                            <div className="input-group">
                                <SelectWithLabel
                                    options={provider}
                                    name="provider_id"
                                    wrappedClass="col-lg-2 col-md-6"
                                    form={props.form}
                                    label={"Nhà cung cấp"}
                                    isRequired={true}
                                    isDisabled={disableField}
                                    defaultValue={"1"}
                                    onChange={onChangeProvider}
                                />
                                {props.form.getFieldValue("is_organization") === "1" ? (
                                    <SelectWithLabel
                                        options={{
                                            1: 'Chứng minh nhân dân',
                                            2: 'Hộ chiếu',
                                            3: 'Căn cước công dân',
                                            4: 'Mã bảo hiểm xã hội',
                                            5: 'Mã nhân viên',
                                        }}
                                        name="identity_type"
                                        wrappedClass="col-lg-2 col-md-6"
                                        form={props.form}
                                        label={"Loại định danh"}
                                        isRequired={true}
                                        onChange={onChangeIdenType}
                                        isDisabled={disableIdenType}
                                    />
                                ) : (
                                    <SelectWithLabel
                                        options={{
                                            1: 'Chứng minh nhân dân',
                                            2: 'Hộ chiếu',
                                            3: 'Căn cước công dân',
                                            4: 'Mã bảo hiểm xã hội',
                                        }}
                                        name="identity_type"
                                        wrappedClass="col-lg-2 col-md-6"
                                        form={props.form}
                                        label={"Loại định danh"}
                                        isRequired={true}
                                        onChange={onChangeIdenType}
                                        isDisabled={disableIdenType}
                                    />
                                )}
                                <InputWithLabel
                                    form={props.form}
                                    label={labelCode}
                                    name="passport"
                                    isRequired={true}
                                    wrapClass="col-lg-2 col-md-6"
                                    maxLength={16}
                                    onChange={changeCode}
                                    isDisabled={disableCode}
                                />
                                <RadioWithLabel
                                    options={{1: "Cấp mới", 2: "Gia hạn",3: "Chuyển đổi",}}
                                    label="Đối tượng"
                                    name="object"
                                    wrappedClass="col-md-6 col-lg-3 select-doi-tuong pr-0"
                                    form={props.form}
                                    isRequired={true}
                                    onChange={changeObject}
                                    isDisabled={disableObject}
                                />
                                {/* {Number(props.form.getFieldValue("object")) === 2 &&
                                <RadioWithLabel
                                    options={{
                                        1: "Trước hạn",
                                        2: "Quá hạn",
                                    }}
                                    label="Loại gia hạn"
                                    name="type_extend"
                                    wrappedClass="col-md-6 col-lg-3"
                                    form={props.form}
                                    isRequired={true}
                                    onChange={onChangeTypeExtend}
                                />
                                } */}
                            </div>
                            <div className="input-group">
                                <RadioWithLabel
                                    options={{
                                        1: "Token",
                                        2: "HSM",
                                        // 3: "FastCA RS"
                                }}
                                    label="Loại thiết bị đầu cuối thuê bao"
                                    name="type_device"
                                    wrappedClass="col-lg-2 col-md-6 select-doi-tuong"
                                    form={props.form}
                                    isRequired={true}
                                    isDisabled={disableTypeDevice}
                                    onChange={changeTypeDevice}
                                />
                                {Number(props.form.getFieldValue("type_device")) === 2 ? (
                                    <RadioWithLabel
                                        options={{1: "CyberLotus", 2: "Khác"}}
                                        label="Nhà cung cấp HSM"
                                        name="hsm_provider"
                                        wrappedClass="col-md-6 col-lg-2 select-doi-tuong"
                                        form={props.form}
                                        isRequired={true}
                                        onChange={onChangeHSMProvider}
                                        isDisabled={disableHSMProvider}
                                    />
                                ) : ("")}
                                <SelectWithLabel
                                    options={cateServicePackage}
                                    name="package_id"
                                    wrappedClass="col-lg-2 col-md-6"
                                    form={props.form}
                                    label={"Gói dịch vụ"}
                                    isRequired={true}
                                    onChange={onChange}
                                    isDisabled={disableField}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Giá bán"
                                    name="package_price"
                                    wrapClass="col-lg-2 col-md-6"
                                    isRequired={true}
                                    isDisabled={disableField}
                                    onBlur={onChangePrice}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Số lượng"
                                    name="quantity"
                                    wrapClass="col-lg-1 col-md-6"
                                    isRequired={true}
                                    type={'number'}
                                    defaultValue={1}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Mã CTKM"
                                    name="promotion_code"
                                    wrapClass="col-md-6 col-lg-2"
                                    maxLength={255}
                                    isRequired={false}
                                    onChange={onChangePromotionCode}
                                />
                            </div>
                            <div className="input-group">
                                {Number(props.form.getFieldValue("object")) === 2 || Number(props.form.getFieldValue("object")) === 3? (
                                    <React.Fragment>
                                        <InputWithLabel
                                            form={props.form}
                                            label="Serial CTS"
                                            name="serial_cts"
                                            wrapClass="col-lg-4 col-md-6"
                                            isRequired={requiredSerialCts}
                                            onBlur={onChangeAdjourn}
                                            // isDisabled={disableField}
                                            isDisabled={false}
                                        />
                                        <SelectWithLabel
                                            options={{1: "Token mới", 2: "Token cũ"}}
                                            name="token_type"
                                            wrappedClass="col-lg-2 col-md-6"
                                            form={props.form}
                                            label={"Loại token"}
                                            isRequired={requiredTokenType}
                                            isDisabled={disableField}
                                        />
                                    </React.Fragment>
                                ) : ('')}
                                {Number(props.form.getFieldValue('provider_id')) === 2 && Number(props.form.getFieldValue('type_device')) === 1 ? (
                                    <SelectWithLabel
                                        options={versionToken}
                                        name="version_token_newtel"
                                        wrappedClass="col-lg-2 col-md-6"
                                        form={props.form}
                                        label={"Version token"}
                                        isRequired={true}
                                        isDisabled={disableField}
                                    />
                                ) : ("")}
                                {Number(props.form.getFieldValue("object")) === 2 || Number(props.form.getFieldValue("object")) === 3 ? (
                                    <SelectDateWithLabel
                                        name="date_expired_cert"
                                        form={props.form}
                                        wrapClass="col-md-2 col-lg-2"
                                        label="Ngày hết hạn"
                                    />
                                ) : ("")}
                            </div>
                            <div className="input-group">
                                <div className={'col-md-12'}>
                                    <Form.Item>
                                        <Checkbox onChange={onChangeCheckBox}>Hiển thị Email</Checkbox>
                                        <span className={'text-danger font-italic'}>
                                            ( Chú ý: Nếu chọn Email sẽ hiển thị trong thông tin CTS )
                                        </span>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card className="container-fluid mt-4" title={<label>Thông tin cá nhân đăng ký</label>}
                          size="small">
                        <div className="input-group">
                            <div className="input-group">
                                <SelectDateWithLabel
                                    name="passport_date"
                                    form={props.form}
                                    isRequired={true}
                                    wrapClass="col-lg-2 col-md-6"
                                    label="Ngày cấp"
                                    rules={[
                                        {
                                            validator: function (rule, value, callback) {
                                                if (value && value > moment()) {
                                                    callback("Ngày cấp phải nhỏ hơn ngày hiện tại");
                                                } else {
                                                    callback();
                                                }
                                            },
                                            message: "Ngày cấp phải nhỏ hơn ngày hiện tại"
                                        }
                                    ]}
                                    isDisabled={disableField}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Nơi cấp"
                                    name="passport_place"
                                    isRequired={true}
                                    wrapClass="col-lg-2 col-md-6"
                                    maxLength={255}
                                    isDisabled={disableField}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="MST (nếu có)"
                                    name="tax_code"
                                    wrapClass="col-lg-2 col-md-6"
                                    maxLength={16}
                                    isDisabled={disableField}
                                />
                                <RadioWithLabel
                                    options={{1: "Upload 1 file", 2: "Upload 2 file"}}
                                    label="Hình thức upload"
                                    name="passport_upload_type"
                                    wrappedClass="col-lg-2 col-md-6"
                                    form={props.form}
                                    isRequired={requireFileWhenExtend}
                                    isDisabled={disableField}
                                    onChange={onChangeTypeUpload}
                                />

                                {typeUpload === 1 ? (
                                    <InputFileUpload
                                        nameFiledSetValue={""}
                                        defaultLabel={props.defaultFileLabel}
                                        classWrapped={"col-md-6 col-lg-4 white-space-initial"}
                                        label={"File CMND/Hộ chiếu"}
                                        name={"passport_file"}
                                        form={props.form}
                                        isRequired={requireFileWhenExtend}
                                        isDisabled={props.disable}
                                        onClickDownloadFile={props.onClickDownloadFile}
                                        isPassport={true}
                                        note={true}
                                        extentionsAllow={['pdf', 'PDF', 'png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']}
                                        accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    />
                                ) : (
                                    <div className={"col-md-6 col-lg-4 d-flex"}>
                                        <InputFileUpload
                                            nameFiledSetValue={""}
                                            defaultLabel={props.defaultFileLabel}
                                            classWrapped={"col-md-6 col-lg-6 no-padding-left"}
                                            label={"CMND (Mặt trước)"}
                                            name={"passport_front_side"}
                                            form={props.form}
                                            isRequired={!props.isUpdateForm}
                                            isDisabled={props.disable}
                                            onClickDownloadFile={props.onClickDownloadFile}
                                            isPassport={true}
                                            note={true}
                                            extentionsAllow={['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']}
                                            accept={[".png", ".jpg", ".jpeg"]}
                                        />
                                            <InputFileUpload
                                                nameFiledSetValue={""}
                                                defaultLabel={props.defaultFileLabel}
                                                classWrapped="col-md-6 col-lg-6 no-padding-right"
                                                label="CMND (Mặt sau)"
                                                name="passport_back_side"
                                                form={props.form}
                                                isRequired={!props.isUpdateForm}
                                                isDisabled={props.disable}
                                                onClickDownloadFile={props.onClickDownloadFile}
                                                isPassport={true}
                                                note={true}
                                                extentionsAllow={['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG']}
                                                accept={[".png", ".jpg", ".jpeg"]}
                                            />
                                    </div>
                                )}

                            </div>
                            <div className="input-group ">
                                <InputWithLabel
                                    form={props.form}
                                    label="Họ tên cá nhân"
                                    name="fullname"
                                    wrapClass="col-md-6 col-lg-4"
                                    isRequired={true}
                                    maxLength={255}
                                    isDisabled={disableField}
                                    onBlur={(e) => onChangeFormatInput('fullname', e.target.value, 3)}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Số điện thoại"
                                    name="phone"
                                    wrapClass="col-md-6 col-lg-4"
                                    isRequired={true}
                                    maxLength={16}
                                    isDisabled={disableField}
                                />
                                <InputWithLabel
                                    form={props.form}
                                    label="Email"
                                    name="email"
                                    wrapClass="col-md-6 col-lg-4"
                                    isRequired={true}
                                    maxLength={255}
                                    isDisabled={disableField}
                                />
                            </div>
                            <div className="input-group">
                                {/*<RadioWithLabel*/}
                                {/*    options={{1: "Có đăng ký", 2: "Không đăng ký"}}*/}
                                {/*    label="ĐK hỗ trợ khẩn cấp"*/}
                                {/*    name="support_register"*/}
                                {/*    wrappedClass="col-md-6 col-lg-4 radio_register_support"*/}
                                {/*    form={props.form}*/}
                                {/*    isRequired={true}*/}
                                {/*    isDisabled={disableField}*/}
                                {/*/>*/}
                                <InputWithLabel
                                    form={props.form}
                                    label="Địa chỉ thường trú"
                                    name="address"
                                    wrapClass="col-md-6 col-lg-4"
                                    maxLength={255}
                                    isDisabled={disableField}
                                />
                                <SelectWithLabel
                                    options={province}
                                    name="province_code"
                                    wrappedClass="col-lg-2 col-md-6"
                                    form={props.form}
                                    label={"Tỉnh thành"}
                                    isRequired={true}
                                    isProvince={true}
                                    onChange={onChangeProvince}
                                    isDisabled={disableField}
                                />
                                <SelectWithLabel
                                    options={district}
                                    name="district_code"
                                    wrappedClass="col-lg-2 col-md-6"
                                    form={props.form}
                                    label={"Quận huyện"}
                                    isRequired={true}
                                    isDisabled={disableField}
                                />
                                <div className="font-size-12 text-danger col-12">
                                    Lưu ý: Thông tin Số điện thoại và Email bắt buộc nhập chính xác để xác minh khách
                                    hàng và nhận hỗ trợ dịch vụ từ NCC
                                </div>
                            </div>
                        </div>
                    </Card>
                    {/*<Card className="container-fluid mt-4"*/}
                    {/*      title={<label>Đăng ký sử dụng dịch vụ hỗ trợ trường hợp khẩn cấp (nếu cần) </label>}*/}
                    {/*      size="small">*/}
                    {/*    {props.form.getFieldValue("support_register") === "1" ? (*/}
                    {/*        <React.Fragment>*/}
                    {/*            <div className="input-group">*/}
                    {/*                <div className="input-group">*/}
                    {/*                    <InputWithLabel*/}
                    {/*                        form={props.form}*/}
                    {/*                        label="Họ tên đầu mối"*/}
                    {/*                        name="fullname_support"*/}
                    {/*                        wrapClass="col-lg-3 col-md-6"*/}
                    {/*                        isRequired={true}*/}
                    {/*                        maxLength={255}*/}
                    {/*                        isDisabled={disableField}*/}
                    {/*                        onBlur={(e) => onChangeFormatInput('fullname_support', e.target.value, 1)}*/}
                    {/*                    />*/}
                    {/*                    <InputWithLabel*/}
                    {/*                        form={props.form}*/}
                    {/*                        label="Chức vụ"*/}
                    {/*                        name="position_support"*/}
                    {/*                        wrapClass="col-lg-3 col-md-6"*/}
                    {/*                        maxLength={255}*/}
                    {/*                        isDisabled={disableField}*/}
                    {/*                    />*/}
                    {/*                    <InputWithLabel*/}
                    {/*                        form={props.form}*/}
                    {/*                        label="Email"*/}
                    {/*                        name="email_support"*/}
                    {/*                        wrapClass="col-lg-3 col-md-6"*/}
                    {/*                        isRequired={true}*/}
                    {/*                        maxLength={255}*/}
                    {/*                        isDisabled={disableField}*/}
                    {/*                    />*/}
                    {/*                    <InputWithLabel*/}
                    {/*                        form={props.form}*/}
                    {/*                        label="Số điện thoại"*/}
                    {/*                        name="phone_support"*/}
                    {/*                        wrapClass="col-lg-3 col-md-6"*/}
                    {/*                        isRequired={true}*/}
                    {/*                        maxLength={16}*/}
                    {/*                        isDisabled={disableField}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </React.Fragment>*/}
                    {/*    ) : (*/}
                    {/*        ""*/}
                    {/*    )}*/}
                    {/*</Card>*/}
                    <Card className="container-fluid mt-4" title={<label>Mẫu đăng ký</label>} size="small">
                        <div className="input-group">
                            <RadioWithLabel
                                options={{1: "Điện tử", 2: "Giấy", 3: "Scan"}}
                                label="Loại hồ sơ"
                                name="type_docurment"
                                wrappedClass="col-lg-3 col-md-6 select-doi-tuong "
                                form={props.form}
                                isRequired={true}
                                isDisabled={disableField}
                                onChange={onChangeTypeDoc}
                            />
                            <div className="col-lg-3 col-md-6 mb-2">
                                <label className="invisible label-hide">11111998</label>
                                <button onClick={() => generateFile(1)}
                                        className="btn btn-outline-success form-control">
                                    Tải xuống DK-01.02
                                </button>

                            </div>
                            {Number(props.form.getFieldValue("provider_id")) === 2 ? (
                                <div className="col-lg-3 col-md-6 mb-2">
                                    <label className="invisible label-hide">11111998</label>
                                    <button onClick={() => generateFile(2)}
                                            className="btn btn-outline-primary form-control">
                                        Tải xuống DK-02
                                    </button>
                                </div>
                            ) : ("")}
                            {/*<div className="col-lg-3 col-md-6 mb-2">*/}
                            {/*    <label className="invisible label-hide">11111998</label>*/}
                            {/*    <button onClick={() => generateFile(3)}*/}
                            {/*            className="btn btn-outline-primary form-control">*/}
                            {/*        Tải xuống DK-03*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                        </div>
                        <div className='input-group m-t-xs-4'>
                            <InputFileUpload
                                defaultLabel={dk01}
                                // onClickDownloadFile={() => onPreviewFile(4)}
                                classWrapped="col-lg-3 col-md-6 input-upload-file"
                                label="Tải file DK-01.02 (Đã ký)"
                                name="file_register_paper"
                                form={props.form}
                                extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}
                                accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                isDisabled={disableField}
                                isRequired={!props.isUpdateForm}
                                onChangeSetField = {changeSetField}
                                nameFiledSetValue={"date_expired_cert"}
                            />
                            {Number(props.form.getFieldValue("provider_id")) === 2 ? (
                                <InputFileUpload
                                    nameFiledSetValue={""}
                                    classWrapped="col-lg-3 col-md-6 input-upload-file"
                                    label="Tải file DK-02 (Nếu có)"
                                    name="file_dk_02"
                                    form={props.form}
                                    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}
                                    accept={[".pdf", ".png", ".jpg", ".jpeg"]}
                                    isDisabled={disableField}
                                />
                            ) : ("")}
                            {/*<InputFileUpload*/}
                            {/*    nameFiledSetValue={""}*/}
                            {/*    classWrapped="col-lg-3 col-md-6 input-upload-file"*/}
                            {/*    label="Tải file DK-03 (Đã ký) (Nếu có)"*/}
                            {/*    name="file_dk_03"*/}
                            {/*    form={props.form}*/}
                            {/*    extentionsAllow={['pdf', 'PDF', 'png', 'jpg']}*/}
                            {/*    accept={[".pdf", ".png", ".jpg", ".jpeg"]}*/}
                            {/*    isDisabled={disableField}*/}
                            {/*/>*/}
                        </div>
                    </Card>
                </Form>
                <div className="input-group d-flex flex-wrap justify-content-center p-5 mt-2">
                    <div className="mt-2">
                        <ButtonOnSave
                            onClick={() => {
                                storeRequest(1);
                            }}
                            label="Lưu nháp"
                            className={"btn btn-primary btn-sm "}
                        />
                    </div>
                    <div className="mt-2">
                        <ButtonOnSave
                            onClick={() => {
                                storeRequest(2);
                            }}
                            label="Trình duyệt"
                            className={"btn btn-success btn-sm "}
                        />
                    </div>
                    <div className="mt-2">
                        <ButtonCancel
                            onClick={() => {
                                props.history.goBack();
                            }}
                            className={"btn btn-default btn-sm"}
                        />
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
};

const WrappedRequestCTSPersonalCreate = Form.create<Props>({
    name: "RequestCTSPersonal"
})(RequestCTSPersonal);

export default WrappedRequestCTSPersonalCreate;
